<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card>
    <v-img
      height="20%"
      :src="img"
    ></v-img>

    <v-card-text class="d-flex align-center mt-5">
      <v-avatar
        size="54"
        rounded
        color="primary"
        class="v-avatar-light-bg primary--text mt-1"
      >
        <div>
          <span class="font-weight-medium">
            {{ $moment().format('MMM') }}
          </span>
          <p class="text-xl primary--text font-weight-bold mb-0">
            {{ $moment().format('D') }}
          </p>
        </div>
      </v-avatar>

      <div class="ms-3">
        <h4 class="text-base font-weight-semibold">
          {{ $t('dashboard.afiliadoPend') }}
        </h4>
      </div>
    </v-card-text>

    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>

    <v-card-text>
      {{ $t('dashboard.companyConfiguration') }}: {{ $t('dashboard.card.static1') }}
      <br />
      {{ $t('dashboard.perfil') }}: {{ $t('dashboard.card.static2') }}
    </v-card-text>

    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>

    <v-card-text>
      {{ $t('dashboard.solicitado') }}: {{ $t('dashboard.card.static3') }}
    </v-card-text>

    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>

    <v-card-text>
      <v-btn
        color="primary"
        block
        outlined
      >
        {{ $t('btn.gestion') }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiStarOutline,
  mdiCheckCircleOutline,
  mdiDotsHorizontal,
  mdiClockOutline,
  mdiMapMarkerOutline,
} from '@mdi/js'

export default {
  data() {
    return {
      // eslint-disable-next-line import/no-unresolved
      img: require('@/assets/img/afiliado/afiliado1.svg'),
      imgs: [],
      permisos: localStorage.getItem('permisos_auth'),
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
        mdiStarOutline,
        mdiCheckCircleOutline,
        mdiDotsHorizontal,
        mdiClockOutline,
        mdiMapMarkerOutline,
      },
      developerMeetup: [
        { icon: mdiClockOutline, title: 'Tuesday, 24 january, 10:20 - 12:30', subtitle: 'After 1 week' },
        { icon: mdiMapMarkerOutline, title: 'The Rochard NYC', subtitle: '1305 Lexington Ave, New York' },
      ],
    }
  },
  computed: {
    ...mapState({
      afiliadoPendient: state => state.app.afiliadoPendient,
    }),
  },
  created() {
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/afiliado/afiliado1.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/afiliado/afiliado2.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/afiliado/afiliado3.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/afiliado/afiliado4.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/afiliado/afiliado5.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/afiliado/afiliado6.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/afiliado/afiliado7.svg'))

    this.shuffleDeck()
  },
  methods: {
    shuffleDeck() {
      const numbers = [...this.imgs]
      let first
      let second
      let temp
      const count = numbers.length
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        first = Math.floor(Math.random() * count)
        second = Math.floor(Math.random() * count)
        temp = numbers[first]
        numbers[first] = numbers[second]
        numbers[second] = temp
      }
      this.imgs = numbers
      // eslint-disable-next-line prefer-destructuring
      this.img = this.imgs[0]
    },
  },
}
</script>
<style lang="scss">
#total-revenue-chart {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
    }
  }
}
</style>
