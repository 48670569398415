<template>
  <div v-if="!isLoading">
    <v-container
      grid-list-xs
      class="px-0"
    >
      <v-row>
        <v-col cols="12">
          <template>
            <table>
              <!--NOMBRES-->
              <tr>
                <th
                  colspan="2"
                  class="header"
                  style="min-width:200px"
                >
                  <p
                    class="mb-0 pl-2 text-uppercase"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.cards') }}
                  </p>
                </th>
                <th width="20px"></th>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <th
                    :key="Math.random() * (index + 1)"
                    class=""
                  >
                    <div class="d-flex justify-center">
                      {{ item.name }}
                    </div>
                  </th>
                  <th
                    :key="Math.random() * (index + 1)"
                    style="min-width: 50px;"
                    class="header_top"
                  ></th>
                </template>
              </tr>
              <!--ICONOS--
              <tr>
                <th
                  colspan="2"
                  class="header"
                ></th>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <th
                    :key="Math.random() * (index + 1)"
                    class="header_top_second"
                  >
                    <div class="d-flex justify-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.mdiEye }}
                          </v-icon>
                        </template>
                        <span>{{ $t('btn.see') }}</span>
                      </v-tooltip>
                    </div>
                  </th>
                  <th
                    :key="Math.random() * (index + 1)"
                    class="header_top_second"
                  >
                    <div class="d-flex justify-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.mdiSquareEditOutline }}
                          </v-icon>
                        </template>
                        <span>{{ $t('btn.edit') }}</span>
                      </v-tooltip>
                    </div>
                  </th>
                  <th
                    :key="Math.random() * (index + 1)"
                    class="header_top_second"
                  >
                    <div class="d-flex justify-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </template>
                        <span>{{ $t('btn.insert') }}</span>
                      </v-tooltip>
                    </div>
                  </th>
                  <th
                    :key="Math.random() * (index + 1)"
                    class="header_top_second"
                  >
                    <div class="d-flex justify-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.mdiDelete }}
                          </v-icon>
                        </template>
                        <span>{{ $t('btn.delete') }}</span>
                      </v-tooltip>
                    </div>
                  </th>
                  <th
                    :key="Math.random() * (index + 1)"
                    class="header_top_second"
                  ></th>
                </template>
              </tr>-->

              <!--BOKING--
              <tr>
                <td
                  colspan="2"
                  class="leftColMine header"
                  style="min-width:140px"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('btn.all') }}
                  </p>
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <Booking
                    :key="index"
                    :index="index"
                  />
                </template>
              </tr>-->
              <!--CRITICOS-->
              <tr
                v-if="cards.includes('data-general')
                  || cards.includes('dns')
                  || cards.includes('markups')
                  || cards.includes('chatBot')
                  || cards.includes('cintillo-create-users')
                "
              >
                <td
                  :colspan="cantRows"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    <b>{{ $t('dashboard.notification1') }}</b>
                  </p>
                  <v-divider></v-divider>
                </td>
              </tr>
              <tr v-if="cards.includes('data-general')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.critical.card1') }}
                  </p>
                </td>
                <td>
                  <v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('data-general', 'dashboard.critical.card1')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="data-general"
                  />
                </template>
              </tr>
              <!--dns-->
              <tr v-if="cards.includes('dns')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.critical.card2') }}
                  </p>
                </td>
                <td>
                  <v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('dns', 'dashboard.critical.card2')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="dns"
                  />
                </template>
              </tr>
              <!--markups-->
              <tr v-if="cards.includes('markups')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.critical.card3') }}
                  </p>
                </td>
                <td>
                  <v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('markups', 'dashboard.critical.card3')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="markups"
                  />
                </template>
              </tr>
              <!--chatBot-->
              <tr v-if="cards.includes('chatBot')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.critical.card4') }}
                  </p>
                </td>
                <td>
                  <v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('chatBot', 'dashboard.critical.card4')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="chatBot"
                  />
                </template>
              </tr>
              <!--cintillo-create-users-->
              <tr v-if="cards.includes('cintillo-create-users')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.critical.card5') }}
                  </p>
                </td>
                <td>
                  <v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('cintillo-create-users', 'dashboard.critical.card5')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="cintillo-create-users"
                  />
                </template>
              </tr>

              <!--IMPORTANTES-->
              <tr
                v-if="cards.includes('bot-telegram')
                  || cards.includes('products-sin-contrates')
                  || cards.includes('products-sin-public')
                  || cards.includes('download-apps')
                  || cards.includes('afiliado-pendients')
                "
              >
                <td
                  :colspan="cantRows"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    <b>{{ $t('dashboard.notification2') }}</b>
                  </p>
                  <v-divider></v-divider>
                </td>
              </tr>
              <!--bot-telegram-->
              <tr v-if="cards.includes('bot-telegram')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.necesary.card1') }}
                  </p>
                </td>
                <td>
                  <!--<v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('bot-telegram', 'dashboard.necesary.card1')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>-->
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="bot-telegram"
                  />
                </template>
              </tr>
              <!--products-sin-contrates-->
              <tr v-if="cards.includes('products-sin-contrates')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.necesary.card2') }}
                  </p>
                </td>
                <td>
                  <!--<v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('products-sin-contrates', 'dashboard.necesary.card2')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>-->
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="products-sin-contrates"
                  />
                </template>
              </tr>
              <!--products-sin-public-->
              <tr v-if="cards.includes('products-sin-public')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.necesary.card3') }}
                  </p>
                </td>
                <td>
                  <!--<v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('products-sin-public', 'dashboard.necesary.card3')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>-->
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="products-sin-public"
                  />
                </template>
              </tr>
              <!--download-apps-->
              <tr v-if="cards.includes('download-apps')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.necesary.card4') }}
                  </p>
                </td>
                <td>
                  <v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('download-apps', 'dashboard.necesary.card4')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="download-apps"
                  />
                </template>
              </tr>
              <!--afiliado-pendients-->
              <tr v-if="cards.includes('afiliado-pendients')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.necesary.card5') }}
                  </p>
                </td>
                <td>
                  <v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('afiliado-pendients', 'dashboard.necesary.card5')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="afiliado-pendients"
                  />
                </template>
              </tr>

              <!--GENERAL-->
              <tr
                v-if="cards.includes('vencimiento-contrates')
                  || cards.includes('ventas-totales')
                  || cards.includes('ventas-contrate')
                  || cards.includes('moderaciones')
                  || cards.includes('api-deepl-manager')
                  || cards.includes('api-deepl-count')
                "
              >
                <td
                  :colspan="cantRows"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    <b>{{ $t('dashboard.notification3') }}</b>
                  </p>
                  <v-divider></v-divider>
                </td>
              </tr>
              <!--vencimiento-contrates-->
              <tr v-if="cards.includes('vencimiento-contrates')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.important.card1') }}
                  </p>
                </td>
                <td>
                  <v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('vencimiento-contrates', 'dashboard.important.card1')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="vencimiento-contrates"
                  />
                </template>
              </tr>
              <!--ventas-totales-->
              <tr v-if="cards.includes('ventas-totales')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.important.card2') }}
                  </p>
                </td>
                <td>
                  <v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('ventas-totales', 'dashboard.important.card2')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="ventas-totales"
                  />
                </template>
              </tr>
              <!--ventas-contrate-->
              <tr v-if="cards.includes('ventas-contrate')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.important.card3') }}
                  </p>
                </td>
                <td>
                  <v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('ventas-contrate', 'dashboard.important.card3')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="ventas-contrate"
                  />
                </template>
              </tr>
              <!--moderaciones-->
              <tr v-if="cards.includes('moderaciones')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.important.card4') }}
                  </p>
                </td>
                <td>
                  <!--<v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('moderaciones', 'dashboard.important.card4')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>-->
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="ventas-contrate"
                  />
                </template>
              </tr>
              <!--api-deepl-manager-->
              <tr v-if="cards.includes('api-deepl-manager')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.important.card5') }}
                  </p>
                </td>
                <td>
                  <v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('api-deepl-manager', 'dashboard.important.card5')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="api-deepl-manager"
                  />
                </template>
              </tr>
              <!--api-deepl-count-->
              <tr v-if="cards.includes('api-deepl-count')">
                <td
                  colspan="2"
                  class="leftColMine header"
                >
                  <p
                    class="mb-0 pl-2"
                    style="font-size: 14px;"
                  >
                    {{ $t('dashboard.important.card6') }}
                  </p>
                </td>
                <td>
                  <v-tooltip
                    max-width="350"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="cursor-pointer"
                        v-bind="attrs"
                        @click="showModal('api-deepl-count', 'dashboard.important.card6')"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('dashboard.cards.modal') }}</span>
                  </v-tooltip>
                </td>
                <template v-for="(item, index) in $store.state.app.sourcePermisos">
                  <NameColumn
                    :key="index"
                    :index="index"
                    card="api-deepl-count"
                  />
                </template>
              </tr>
            </table>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-if="isShowModal"
      v-model="isShowModal"
      scrollable
      :max-width="tagModal === 'data-general' ? '580px' : (
        tagModal === 'dns' ? '500px' : (
          tagModal === 'markups' ? '500px' : (
            tagModal === 'chatBot' ? '450px' : (
              tagModal === 'cintillo-create-users' ? 'auto' : (
                tagModal === 'download-apps' ? 'auto' : (
                  tagModal === 'afiliado-pendients' ? '450px' : (
                    tagModal === 'vencimiento-contrates' ? '450px' : (
                      tagModal === 'ventas-totales' ? '500px' : (
                        tagModal === 'ventas-contrate' ? '450px' : (
                          tagModal === 'api-deepl-manager' ? 'auto' : (
                            tagModal === 'api-deepl-count' ? '550px' : '550px'
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )"
    >
      <v-card
        :max-height="tagModal === 'data-general' ? '600px' : (
          tagModal === 'dns' ? '600px' : (
            tagModal === 'markups' ? '600px' : (
              tagModal === 'chatBot' ? '550px' : (
                tagModal === 'cintillo-create-users' ? 'auto' : (
                  tagModal === 'download-apps' ? 'auto' : (
                    tagModal === 'afiliado-pendients' ? 'auto' : (
                      tagModal === 'vencimiento-contrates' ? '550px' : (
                        tagModal === 'ventas-totales' ? '550px' : (
                          tagModal === 'ventas-contrate' ? '450px' : (
                            tagModal === 'api-deepl-manager' ? '450px' : (
                              tagModal === 'api-deepl-count' ? 'auto' : '550px'
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )"
      >
        <v-card-title>{{ $t(txtModal) }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
              class="pt-5"
            >
              <DataGeneral
                v-if="tagModal === 'data-general'"
              />
              <DNS
                v-else-if="tagModal === 'dns'"
              />
              <Markups
                v-else-if="tagModal === 'markups'"
              />
              <ChatPendient
                v-else-if="tagModal === 'chatBot'"
              />
              <v-row v-else-if="tagModal === 'cintillo-create-users'">
                <v-col cols="12">
                  <CintilloCreateUser />
                </v-col>
                <v-col cols="12">
                  <CintilloCreateAdmin />
                </v-col>
                <v-col cols="12">
                  <CintilloCreateAgente />
                </v-col>
                <v-col cols="12">
                  <CintilloCreateSeller />
                </v-col>
              </v-row>
              <AppDownload
                v-else-if="tagModal === 'download-apps'"
              />
              <AfiliadoPendiente
                v-else-if="tagModal === 'afiliado-pendients'"
              />
              <VencimientoHotelsAll
                v-else-if="tagModal === 'vencimiento-contrates'"
              />
              <VentasMes
                v-else-if="tagModal === 'ventas-totales'"
              />
              <VentasMesByContrateHotel
                v-else-if="tagModal === 'ventas-contrate'"
              />
              <DeepLOff
                v-else-if="tagModal === 'api-deepl-manager'"
              />
              <DeepLCount
                v-else-if="tagModal === 'api-deepl-count'"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isShowModal = !isShowModal"
          >
            {{ $t('btn.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiLock,
  mdiCar,
  mdiBed,
  mdiWalletTravel,
  mdiSeatPassenger,
  mdiAirplaneTakeoff,
  mdiAirplaneLanding,
  mdiAirplane,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiDelete,
  mdiChevronRight,
  mdiChevronDown,
  mdiMenuDown,
  mdiMenuRight,
} from '@mdi/js'

import NameColumn from '../utils/cards/NameColumn.vue'
import DataGeneral from '../utils/cards/DataGeneral.vue'
import DNS from '../utils/cards/DNS.vue'
import Markups from '../utils/cards/Markups.vue'
import ChatPendient from '../utils/cards/ChatPendient.vue'
import CintilloCreateUser from '../utils/cards/CintilloCreateUser.vue'
import CintilloCreateAdmin from '../utils/cards/CintilloCreateAdmin.vue'
import CintilloCreateSeller from '../utils/cards/CintilloCreateSeller.vue'
import CintilloCreateAgente from '../utils/cards/CintilloCreateAgente.vue'
import AppDownload from '../utils/cards/AppDownload.vue'
import AfiliadoPendiente from '../utils/cards/AfiliadoPendiente.vue'
import VencimientoHotelsAll from '../utils/cards/VencimientoHotelsAll.vue'
import VentasMes from '../utils/cards/VentasMes.vue'
import VentasMesByContrateHotel from '../utils/cards/VentasMesByContrateHotel.vue'
import DeepLOff from '../utils/cards/DeepLOff.vue'
import DeepLCount from '../utils/cards/DeepLCount.vue'

export default {
  components: {
    NameColumn,
    DataGeneral,
    DNS,
    Markups,
    ChatPendient,
    CintilloCreateUser,
    CintilloCreateAdmin,
    CintilloCreateSeller,
    CintilloCreateAgente,
    AppDownload,
    AfiliadoPendiente,
    VencimientoHotelsAll,
    VentasMes,
    VentasMesByContrateHotel,
    DeepLOff,
    DeepLCount,
  },
  data() {
    return {
      isLoading: true,
      isShowModal: false,
      tagModal: null,
      txtModal: '',
      checkboxVer: [],
      items: [],
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiLock,
        mdiCar,
        mdiBed,
        mdiWalletTravel,
        mdiSeatPassenger,
        mdiAirplaneTakeoff,
        mdiAirplaneLanding,
        mdiAirplane,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiDelete,
        mdiChevronRight,
        mdiChevronDown,
        mdiMenuDown,
        mdiMenuRight,
      },
      showBooking: true,
      showProduct: true,
      showContrate: true,
      showAdministration: true,
      showConfiguration: true,
      permisos: localStorage.getItem('permisos_auth'),
      cards: localStorage.getItem('cards_auth'),
      is_nivel_propietario: false,
      user: {},
      modules: [],
      modulesCheck: [],
    }
  },
  computed: {
    ...mapState({
      userUpdate: state => state.app.userUpdate,
      isLoadingPagePermisos: state => state.app.isLoadingPagePermisos,
      viewPermisos: state => state.app.viewPermisos,
    }),
    cantRows() {
      let result = parseInt(this.$store.state.app.sourcePermisos.length, 10)
      result += 8

      return result
    },
  },
  created() {
    this.profile()
    if (this.viewPermisos === 'perfil_cards') {
      this.getPerfiles()
    } else if (this.viewPermisos === 'rols_cards') {
      this.getRoles()
    } else if (this.viewPermisos === 'users_cards') {
      this.getUser()
    }

    this.modules.push({
      type: 'critical',
      slug: 'data-general',
      name: 'Datos generales',
    })
    this.modules.push({
      type: 'critical',
      slug: 'dns',
      name: 'Configuración DNS',
    })
    this.modules.push({
      type: 'critical',
      slug: 'markups',
      name: 'Markups',
    })
    this.modules.push({
      type: 'critical',
      slug: 'chatBot',
      name: 'Conversaciones (ChatBot)',
    })
    this.modules.push({
      type: 'critical',
      slug: 'cintillo-create-users',
      name: 'Banner Usuarios y Roles',
    })

    this.modules.push({
      type: 'necesary',
      slug: 'bot-telegram',
      name: 'Bot Telegram',
    })
    this.modules.push({
      type: 'necesary',
      slug: 'products-sin-contrates',
      name: 'Productos sin contratos',
    })
    this.modules.push({
      type: 'necesary',
      slug: 'products-sin-public',
      name: 'Productos sin publicar',
    })
    this.modules.push({
      type: 'necesary',
      slug: 'download-apps',
      name: 'Banner APPs',
    })
    this.modules.push({
      type: 'necesary',
      slug: 'afiliado-pendients',
      name: 'Nuevos afiliados',
    })

    this.modules.push({
      type: 'important',
      slug: 'vencimiento-contrates',
      name: 'Vencimiento de contratos',
    })
    this.modules.push({
      type: 'important',
      slug: 'ventas-totales',
      name: 'Ventas totales',
    })
    this.modules.push({
      type: 'important',
      slug: 'ventas-contrate',
      name: 'Ventas por contratos',
    })
    this.modules.push({
      type: 'important',
      slug: 'moderaciones',
      name: 'Moderaciones',
    })
    this.modules.push({
      type: 'important',
      slug: 'api-deepl-manager',
      name: 'API DeepL - Gestión',
    })
    this.modules.push({
      type: 'important',
      slug: 'api-deepl-count',
      name: 'API DeepL - Gráfico de traducciones',
    })
  },
  methods: {
    ...mapMutations(['setPermisosTabLoading', 'setSourcePermisos', 'setSourcePermisosPos']),
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
      if (this.user.nivel === -1) {
        this.is_nivel_propietario = true
      }
      if (this.user.company_id) {
        if (this.user.company) {
          if (this.user.company.offline) {
            this.offline = this.user.company.offline
          }
        }
      }
    },
    showModal(ability, txt) {
      this.tagModal = ability
      this.txtModal = txt
      this.isShowModal = true
    },
    getPerfiles() {
      this.axios
        .get('perfil_afiliado/permisos', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.items = res.data.data
            this.$store.state.app.sourcePermisos = this.items
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getRoles() {
      this.axios
        .get(`role/permisos?perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.items = res.data.data
            this.setSourcePermisos(this.items)
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.setPermisosTabLoading(false))
    },
    getUser() {
      if (localStorage.getItem('user-id') !== null) {
        if (localStorage.getItem('user-role-new-id') !== null) {
          this.axios
            .post(
              'user/permisos-role-new',
              { role_id: localStorage.getItem('user-role-new-id') },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              },
            )
            .then(res => {
              // eslint-disable-next-line no-empty
              if (res.data.success === false) {
              } else {
                this.items = res.data.data.data
                this.setSourcePermisos(this.items)
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => this.setPermisosTabLoading(false))
        } else {
          this.axios
            .post(
              'user/permisos',
              { id: localStorage.getItem('user-id') },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              },
            )
            .then(res => {
              // eslint-disable-next-line no-empty
              if (res.data.success === false) {
              } else {
                this.items = res.data.data.data
                this.setSourcePermisos(this.items)
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => this.setPermisosTabLoading(false))
        }
      } else {
        this.axios
          .post(
            'user/permisos',
            { role_id: this.userUpdate.role_id },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            // eslint-disable-next-line no-empty
            if (res.data.success === false) {
            } else {
              this.items = res.data.data.data
              this.setSourcePermisos(this.items)
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => this.setPermisosTabLoading(false))
      }
    },

    checkPerm(pos, ability) {
      let { permisos } = this.sourcePermisos[pos]
      if (permisos.includes(ability)) {
        const result = permisos.filter(e => e !== ability)
        this.setSourcePermisosPos({ pos, update: result })
      } else {
        permisos = permisos.push(ability)
      }
    },
  },
}
</script>

<style scoped>
.demo-content[data-v-566be365]:last-child {
  padding-bottom: 0px;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border-color: white;
  width: auto;
}

td,
th {
  text-align: left;
  padding: 0px 8px;
}

tr:nth-child(even) {
  background-color: #ffffff;
}

td {
  max-width: 40px !important;
}

.font-size-Mine > th {
  font-size: 16px;
  font-weight: lighter;
}

.leftColMine {
  padding: 5px 10px 0px 10px;
}

.header {
  left: 0;
  position: sticky;
  z-index: 2;
  background-color: #ffffff;
}

th {
  padding-top: 0;
}

th {
  padding-top: 0;
}

.overflow_Mine {
  overflow-x: auto !important;
}

.v-expansion-panel-header {
  padding-bottom: 8px !important;
}

#main > .v-expansion-panel-content__wrap {
  padding-left: 0% !important;
  padding-right: 0% !important;
  padding-bottom: 0% !important;
}

#sec > .v-expansion-panel-content__wrap {
  padding-left: 0% !important;
  padding-right: 0% !important;
  margin-bottom: 1rem !important;
}

#sec {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
}

#sec::before {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
}
</style>
