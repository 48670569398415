var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',[_c('v-container',{staticClass:"px-0",attrs:{"grid-list-xs":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[[_c('table',[_c('tr',[_c('th',{staticClass:"header",staticStyle:{"min-width":"200px"},attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2 text-uppercase",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.cards'))+" ")])]),_c('th',{attrs:{"width":"20px"}}),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('th',{key:Math.random() * (index + 1)},[_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('th',{key:Math.random() * (index + 1),staticClass:"header_top",staticStyle:{"min-width":"50px"}})]})],2),(_vm.cards.includes('data-general')
                || _vm.cards.includes('dns')
                || _vm.cards.includes('markups')
                || _vm.cards.includes('chatBot')
                || _vm.cards.includes('cintillo-create-users')
              )?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":_vm.cantRows}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('dashboard.notification1')))])]),_c('v-divider')],1)]):_vm._e(),(_vm.cards.includes('data-general'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.critical.card1'))+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"max-width":"350","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showModal('data-general', 'dashboard.critical.card1')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,771932069)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.cards.modal')))])])],1),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"data-general"}})]})],2):_vm._e(),(_vm.cards.includes('dns'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.critical.card2'))+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"max-width":"350","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showModal('dns', 'dashboard.critical.card2')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,1262671700)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.cards.modal')))])])],1),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"dns"}})]})],2):_vm._e(),(_vm.cards.includes('markups'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.critical.card3'))+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"max-width":"350","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showModal('markups', 'dashboard.critical.card3')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,3322089519)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.cards.modal')))])])],1),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"markups"}})]})],2):_vm._e(),(_vm.cards.includes('chatBot'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.critical.card4'))+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"max-width":"350","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showModal('chatBot', 'dashboard.critical.card4')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,2760645772)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.cards.modal')))])])],1),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"chatBot"}})]})],2):_vm._e(),(_vm.cards.includes('cintillo-create-users'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.critical.card5'))+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"max-width":"350","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showModal('cintillo-create-users', 'dashboard.critical.card5')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,1164776538)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.cards.modal')))])])],1),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"cintillo-create-users"}})]})],2):_vm._e(),(_vm.cards.includes('bot-telegram')
                || _vm.cards.includes('products-sin-contrates')
                || _vm.cards.includes('products-sin-public')
                || _vm.cards.includes('download-apps')
                || _vm.cards.includes('afiliado-pendients')
              )?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":_vm.cantRows}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('dashboard.notification2')))])]),_c('v-divider')],1)]):_vm._e(),(_vm.cards.includes('bot-telegram'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.necesary.card1'))+" ")])]),_c('td'),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"bot-telegram"}})]})],2):_vm._e(),(_vm.cards.includes('products-sin-contrates'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.necesary.card2'))+" ")])]),_c('td'),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"products-sin-contrates"}})]})],2):_vm._e(),(_vm.cards.includes('products-sin-public'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.necesary.card3'))+" ")])]),_c('td'),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"products-sin-public"}})]})],2):_vm._e(),(_vm.cards.includes('download-apps'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.necesary.card4'))+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"max-width":"350","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showModal('download-apps', 'dashboard.necesary.card4')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,1758473631)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.cards.modal')))])])],1),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"download-apps"}})]})],2):_vm._e(),(_vm.cards.includes('afiliado-pendients'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.necesary.card5'))+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"max-width":"350","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showModal('afiliado-pendients', 'dashboard.necesary.card5')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,613601571)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.cards.modal')))])])],1),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"afiliado-pendients"}})]})],2):_vm._e(),(_vm.cards.includes('vencimiento-contrates')
                || _vm.cards.includes('ventas-totales')
                || _vm.cards.includes('ventas-contrate')
                || _vm.cards.includes('moderaciones')
                || _vm.cards.includes('api-deepl-manager')
                || _vm.cards.includes('api-deepl-count')
              )?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":_vm.cantRows}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('dashboard.notification3')))])]),_c('v-divider')],1)]):_vm._e(),(_vm.cards.includes('vencimiento-contrates'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.important.card1'))+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"max-width":"350","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showModal('vencimiento-contrates', 'dashboard.important.card1')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,971011082)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.cards.modal')))])])],1),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"vencimiento-contrates"}})]})],2):_vm._e(),(_vm.cards.includes('ventas-totales'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.important.card2'))+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"max-width":"350","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showModal('ventas-totales', 'dashboard.important.card2')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,305592610)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.cards.modal')))])])],1),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"ventas-totales"}})]})],2):_vm._e(),(_vm.cards.includes('ventas-contrate'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.important.card3'))+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"max-width":"350","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showModal('ventas-contrate', 'dashboard.important.card3')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,2340553667)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.cards.modal')))])])],1),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"ventas-contrate"}})]})],2):_vm._e(),(_vm.cards.includes('moderaciones'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.important.card4'))+" ")])]),_c('td'),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"ventas-contrate"}})]})],2):_vm._e(),(_vm.cards.includes('api-deepl-manager'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.important.card5'))+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"max-width":"350","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showModal('api-deepl-manager', 'dashboard.important.card5')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,2379338260)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.cards.modal')))])])],1),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"api-deepl-manager"}})]})],2):_vm._e(),(_vm.cards.includes('api-deepl-count'))?_c('tr',[_c('td',{staticClass:"leftColMine header",attrs:{"colspan":"2"}},[_c('p',{staticClass:"mb-0 pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.important.card6'))+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"max-width":"350","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showModal('api-deepl-count', 'dashboard.important.card6')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,3248996935)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.cards.modal')))])])],1),_vm._l((_vm.$store.state.app.sourcePermisos),function(item,index){return [_c('NameColumn',{key:index,attrs:{"index":index,"card":"api-deepl-count"}})]})],2):_vm._e()])]],2)],1)],1),(_vm.isShowModal)?_c('v-dialog',{attrs:{"scrollable":"","max-width":_vm.tagModal === 'data-general' ? '580px' : (
      _vm.tagModal === 'dns' ? '500px' : (
        _vm.tagModal === 'markups' ? '500px' : (
          _vm.tagModal === 'chatBot' ? '450px' : (
            _vm.tagModal === 'cintillo-create-users' ? 'auto' : (
              _vm.tagModal === 'download-apps' ? 'auto' : (
                _vm.tagModal === 'afiliado-pendients' ? '450px' : (
                  _vm.tagModal === 'vencimiento-contrates' ? '450px' : (
                    _vm.tagModal === 'ventas-totales' ? '500px' : (
                      _vm.tagModal === 'ventas-contrate' ? '450px' : (
                        _vm.tagModal === 'api-deepl-manager' ? 'auto' : (
                          _vm.tagModal === 'api-deepl-count' ? '550px' : '550px'
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )},model:{value:(_vm.isShowModal),callback:function ($$v) {_vm.isShowModal=$$v},expression:"isShowModal"}},[_c('v-card',{attrs:{"max-height":_vm.tagModal === 'data-general' ? '600px' : (
        _vm.tagModal === 'dns' ? '600px' : (
          _vm.tagModal === 'markups' ? '600px' : (
            _vm.tagModal === 'chatBot' ? '550px' : (
              _vm.tagModal === 'cintillo-create-users' ? 'auto' : (
                _vm.tagModal === 'download-apps' ? 'auto' : (
                  _vm.tagModal === 'afiliado-pendients' ? 'auto' : (
                    _vm.tagModal === 'vencimiento-contrates' ? '550px' : (
                      _vm.tagModal === 'ventas-totales' ? '550px' : (
                        _vm.tagModal === 'ventas-contrate' ? '450px' : (
                          _vm.tagModal === 'api-deepl-manager' ? '450px' : (
                            _vm.tagModal === 'api-deepl-count' ? 'auto' : '550px'
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t(_vm.txtModal)))]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"12","md":"12"}},[(_vm.tagModal === 'data-general')?_c('DataGeneral'):(_vm.tagModal === 'dns')?_c('DNS'):(_vm.tagModal === 'markups')?_c('Markups'):(_vm.tagModal === 'chatBot')?_c('ChatPendient'):(_vm.tagModal === 'cintillo-create-users')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('CintilloCreateUser')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('CintilloCreateAdmin')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('CintilloCreateAgente')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('CintilloCreateSeller')],1)],1):(_vm.tagModal === 'download-apps')?_c('AppDownload'):(_vm.tagModal === 'afiliado-pendients')?_c('AfiliadoPendiente'):(_vm.tagModal === 'vencimiento-contrates')?_c('VencimientoHotelsAll'):(_vm.tagModal === 'ventas-totales')?_c('VentasMes'):(_vm.tagModal === 'ventas-contrate')?_c('VentasMesByContrateHotel'):(_vm.tagModal === 'api-deepl-manager')?_c('DeepLOff'):(_vm.tagModal === 'api-deepl-count')?_c('DeepLCount'):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.isShowModal = !_vm.isShowModal}}},[_vm._v(" "+_vm._s(_vm.$t('btn.close'))+" ")])],1)],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }