<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card
    v-if="show"
    style="cursor: pointer;"
  >
    <v-card-text>
      <v-row class="mx-2">
        <v-col
          cols="12"
        >
          <span
            style="font-size: 20px;"
          >
            <b>
              {{ $t('dashboard.cardDeepLCount1') }}
            </b>
            <br />
            <span style="font-size: 14px;">
              {{ $t('dashboard.cardDeepLCount2') }}
            </span>
          </span>
        </v-col>
      </v-row>
      <v-card-text>
        <!-- Chart -->
        <vue-apex-charts
          :options="chartOptions"
          :series="chartData"
          height="210"
        ></vue-apex-charts>
      </v-card-text>

      <div class="d-flex justify-space-around mt-2">
        <div class="text-center">
          <div class="d-flex align-center justify-center">
            <div class="pa-1 primary rounded-circle me-2"></div>
            <span class="text--primary font-weight-semibold text-base">
              {{ 457891 | number('0a') }}
            </span>
          </div>
          <span>PT</span>
        </div>

        <v-divider vertical></v-divider>
        <div class="text-center">
          <div class="d-flex align-center justify-center">
            <div class="pa-1 success rounded-circle me-2"></div>
            <span class="text--primary font-weight-semibold text-base">
              {{ 45789 | number('0a') }}
            </span>
          </div>
          <span>EN</span>
        </div>

        <v-divider vertical></v-divider>
        <div class="text-center">
          <div class="d-flex align-center justify-center">
            <div class="pa-1 warning rounded-circle me-2"></div>
            <span class="text--primary font-weight-semibold text-base">
              {{ 56465456 | number('0a') }}
            </span>
          </div>
          <span>DE</span>
        </div>
      </div>

      <div class="d-flex justify-space-around mt-2">
        <div class="text-center">
          <div class="d-flex align-center justify-center">
            <div class="pa-1 info rounded-circle me-2"></div>
            <span class="text--primary font-weight-semibold text-base">
              {{ 797987 | number('0a') }}
            </span>
          </div>
          <span>RU</span>
        </div>

        <v-divider vertical></v-divider>
        <div class="text-center">
          <div class="d-flex align-center justify-center">
            <div class="pa-1 error rounded-circle me-2"></div>
            <span class="text--primary font-weight-semibold text-base">
              {{ 321545 | number('0a') }}
            </span>
          </div>
          <span>FR</span>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line import/no-unresolved
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    VueApexCharts,
  },
  data() {
    return {
      permisos: localStorage.getItem('permisos_auth'),
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
      chartOptions: {
        colors: [
          this.$vuetify.theme.currentTheme.primary,
          this.$vuetify.theme.currentTheme.success,
          this.$vuetify.theme.currentTheme.warning,
          this.$vuetify.theme.currentTheme.info,
          this.$vuetify.theme.currentTheme.error,
        ],
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
          offsetX: -15,
        },
        plotOptions: {
          bar: {
            columnWidth: '20%',
            distributed: true,
            borderRadius: 8,
            startingShape: 'rounded',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: ['PT', 'EN', 'DE', 'RU', 'FR'],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tickPlacement: 'on',
          labels: {
            show: false,
            style: {
              fontSize: '12px',
            },
          },
        },
        yaxis: {
          show: true,
          tickAmount: 4,
          labels: {
            offsetY: 3,
            formatter: value => `${kFormatter(value, 0)}`,
          },
        },
        stroke: {
          width: [2, 2],
        },
        grid: {
          strokeDashArray: 12,
          padding: {
            right: 0,
          },
        },
      },
      chartData: [],
      show: false,
    }
  },
  computed: {
    ...mapState({
      deepLCountTranslate: state => state.app.deepLCountTranslate,
    }),
  },
  created() {
    setTimeout(() => {
      this.show = true
    }, 400)
    this.chartData.push({
      name: 'Cant.',
      data: [
        456789,
        456789,
        456789,
        456789,
        456789,
      ],
    })
  },
}
</script>
<style lang="scss">
#total-revenue-chart {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
    }
  }
}
</style>
