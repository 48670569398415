<template>
  <v-card>
    <v-row class="mx-2 my-3">
      <v-col cols="12">
        <span
          style="font-size: 20px;"
        >
          <b>
            {{ $t('dashboard.talkins') }}
          </b>
        </span>
      </v-col>

      <v-col cols="12">
        <v-tooltip
          max-width="350"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >{{ $t('dashboard.card.static6') | truncate(350) }} </span>
          </template>
          <span>{{ $t('dashboard.card.static6') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-card-actions class="dense">
      <v-btn
        color="primary"
        text
        large
      >
        {{ $t('dashboard.talkins4') }}
      </v-btn>
      <v-spacer></v-spacer>
      <span>{{ `${$t('dashboard.talkins1')} 10` }}</span>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
    }
  },
  computed: {
    ...mapState({
      chatBotRead: state => state.app.chatBotRead,
    }),
    message() {
      const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.split('')
      let posicionActual = 500

      while (posicionActual !== 0) {
        const posicionAleatoria = Math.floor(Math.random() * posicionActual)
        // eslint-disable-next-line no-plusplus
        posicionActual--;

        // "truco" para intercambiar los valores sin necesidad de una variable auxiliar
        [caracteres[posicionActual], caracteres[posicionAleatoria]] = [
          caracteres[posicionAleatoria], caracteres[posicionActual]]
      }

      return caracteres.slice(0, 950).join(' ')
    },
  },
}
</script>
