<template>
  <v-card v-if="!isLoading">
    <v-card-text class="align-start">
      <span style="font-size: 20px;">
        <b>{{ $t('dashboard.salesOverview') }}</b>
      </span>
      <br />
      <span style="font-size: 14px;">{{ $t('dashboard.salesOverview1') }}</span>
    </v-card-text>

    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <apexchart
            id="chart-sales-overview"
            width="200"
            :options="chartOptions"
            :series="chartData"
            :color="$vuetify.theme.themes.light.primary"
          ></apexchart>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="d-flex flex-column justify-center"
        >
          <div class="d-flex align-center">
            <v-avatar
              class="v-avatar-light-bg primary--text"
              rounded
              size="40"
            >
              <v-icon
                size="30"
                color="primary"
              >
                {{ icons.mdiCurrencyUsd }}
              </v-icon>
            </v-avatar>
            <div class="ms-4 d-flex flex-column">
              <p class="text--primary mb-0 text-base">
                {{ $t('dashboard.salesAll') }}
              </p>
              <span class="text--primary font-weight-semibold text-xl">
                {{ 4400 | currency('$') }}
              </span>
            </div>
          </div>

          <v-divider class="my-6"></v-divider>

          <table class="sales-overview-stats-table">
            <tr>
              <td>
                <div class="mb-0">
                  <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                  <span>{{ $t('menu.hotels') }}</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">
                  {{ 1500 | currency('$') }}
                </span>
              </td>
              <td>
                <div class="mb-0">
                  <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                  <span>{{ $t('menu.cars') }}</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">
                  {{ 1500 | currency('$') }}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="mb-0">
                  <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                  <span>{{ $t('menu.flights') }}</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">
                  {{ 1400 | currency('$') }}
                </span>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import { addAlpha } from '@core/utils'

export default {
  data() {
    return {
      isLoading: true,
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
      chartOptions: {
        labels: [this.$t('menu.hotels'), this.$t('menu.cars'), this.$t('menu.flights')],
        colors: [
          this.$vuetify.theme.currentTheme.primary,
          addAlpha(this.$vuetify.theme.currentTheme.primary, 0.3),
          addAlpha(this.$vuetify.theme.currentTheme.secondary, 0.1),
        ],
        chart: {
          type: 'donut',
          sparkline: {
            enabled: true,
          },
        },

        plotOptions: { },
      },
      chartData: [],
    }
  },
  computed: {
    ...mapState({
      salesMonth: state => state.app.salesMonth,
    }),
  },
  created() {
    this.chartData.push(1500)
    this.chartData.push(1500)
    this.chartData.push(1400)

    this.chartOptions.plotOptions = {
      pie: {
        donut: {
          size: '90%',

          labels: {
            show: true,
            name: {
              fontSize: '14px',
              offsetY: 25,
            },
            value: {
              fontSize: '2.125rem',
              fontWeight: 600,

              offsetY: -15,
              formatter(value) {
                return `${value}`
              },
            },
            total: {
              show: true,
              label: this.$t('dashboard.salesTotal'),
              color: 'rgba(94, 86, 105, 0.68)',
              formatter(value) {
                let valor = 0
                value.globals.seriesTotals.forEach(element => {
                  valor += element
                })

                return valor.toFixed(2)

                // return `${value.globals.seriesTotals.reduce((total, num) => total + num)}`
              },
            },
          },
        },
      },
    }

    setTimeout(() => {
      this.isLoading = false
    }, 200)
  },
}
</script>
<style lang="scss">
#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;
  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }

  // Set opacity of dots
  tr {
    &:nth-of-type(1) {
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.7;
        }
      }
    }
    &:nth-of-type(2) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.5;
        }
      }
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.15;
        }
      }
    }
  }
}
</style>
