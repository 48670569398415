<template>
  <fragment>
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="checkboxVer"
          hide-details
          class="my-1"
          :disabled="!cardsLogin.includes(card) && user.nivel !== -1"
          @click="checkPerm(index)"
        ></v-checkbox>
      </div>
    </td>
    <td
      :key="Math.random() * (index + 1)"
      style="min-width: 50px;"
    ></td>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    card: {
      type: String,
    },
  },
  data() {
    return {
      isLoading: true,
      checkboxVer: false,
      cardsLogin: localStorage.getItem('cards_auth'),
      user: JSON.parse(localStorage.getItem('profile')),
    }
  },
  computed: {
    ...mapState({
      sourcePermisos: state => state.app.sourcePermisos,
    }),
  },
  created() {
    this.checkboxVer = this.sourcePermisos[this.index].cards.includes(this.card)
  },
  methods: {
    ...mapMutations(['verifyPermisoZona']),
    checkPerm(pos) {
      let { cards } = this.sourcePermisos[pos]
      if (!cards.includes(this.card) && this.checkboxVer) {
        cards = cards.push(this.card)

        // this.verifyPermisoZona(this.index)
      } else if (!this.checkboxVer) {
        const arr = []
        cards.forEach(element => {
          if (element !== this.card) {
            arr.push(element)
          }
        })

        this.sourcePermisos[pos].cards = arr
      }
    },
  },
}
</script>
