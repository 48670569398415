<template>
  <v-card>
    <v-card-text class="align-start">
      <span style="font-size: 20px;">
        <b>{{ $t('dashboard.salesByContrate') }}: {{ $t('lbl.hotel') }}</b>
      </span>
      <br />
      <span style="font-size: 14px;">{{ $t('dashboard.salesByContrate1') }}</span>
    </v-card-text>

    <v-card-text class="d-flex align-center justify-space-between pb-1 pt-5">
      <div>
        <p class="text-4xl font-weight-semibold text--primary mb-2">
          {{ 1500 | currency($) }}
        </p>
        <span class="text-base">{{ $t('dashboard.salesOverview') }}</span>
      </div>

      <div class="chart-wrapper">
        <apexchart
          :options="chartOptions"
          :series="series"
          :color="$vuetify.theme.themes.light.primary"
        ></apexchart>
      </div>
    </v-card-text>

    <v-card-text class="mt-2">
      <div
        v-for="index in 1"
        :key="index"
        :class="`statistics-table d-flex align-center pt-3 ${index > 0 ? 'mt-3':''}`"
      >
        <!--<span :class="`badge-sm ${data.color}`"></span>-->
        <span class="text--primary ms-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >WST022305456</span>
            </template>
            <span>
              <b>{{ $t('lbl.identificador') }}</b>: WST022305456
              <br />
              <b>{{ $t('lbl.proveedor') }}</b>:
              <span>
                <span>
                  {{ $t('dashboard.card.static7') }}
                </span>
              </span>
              <br />
              <b>{{ $t('lbl.operador') }}</b>:
              <br />
              <span>
                <span>
                  {{ $t('dashboard.card.static8') }}
                </span>
              </span>
              <br />
              <b>{{ $t('dashboard.source') }}</b>:
              <span>
                {{ $t('dashboard.direct') }}
              </span>
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <span class="font-weight-semibold text--primary me-6">
          {{ 1500 | currency('$') }}
        </span>
        <span class="font-weight-semibold text--primary me-1">
          50%
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiChevronUp,
  mdiChevronDown,
} from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
        mdiChevronUp,
        mdiChevronDown,
      },
      isLoading: true,
      chartOptions: {},
      series: [],
    }
  },
  computed: {
    ...mapState({
      salesMonth: state => state.app.salesMonth,
    }),
  },
  mounted() {
    this.series.push({
      data: [50, 40, 110, 80, 40, 60, 110],
    })
    this.chartOptions = {
      colors: [this.$vuetify.theme.currentTheme.primary],
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },

      tooltip: {
        x: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '40%',
          startingShape: 'rounded',
          endingShape: 'rounded',
          borderRadius: 4,
        },
      },
    }
    this.isLoading = false
  },
}
</script>
<style lang="scss" scoped>
.chart-wrapper {
  max-width: 130px;
}
.statistics-table {
  border-top: solid 1px rgba(93, 89, 98, 0.14);
  .badge-sm {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    margin: 4px;
  }
}
</style>
