<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card>
    <v-row class="mx-2">
      <v-col
        cols="10"
      >
        <span
          style="font-size: 20px;"
        >
          <b>
            {{ $t('dashboard.card.static4') }}
          </b>
        </span>
        <br />
        <span style="font-size: 14px;">
          {{ $t('dashboard.card.static5') }}
        </span>
      </v-col>
    </v-row>
    <v-row class="my-2 mx-2">
      <v-col
        cols="2"
      >
        <v-card
          :style="`border-left:0.25px solid ${$vuetify.theme.themes.light.primary};border-top:0.25px solid ${$vuetify.theme.themes.light.primary}; border-bottom:1px solid ${$vuetify.theme.themes.light.primary};border-right:1px solid ${$vuetify.theme.themes.light.primary};border-radius: 1rem 1rem 1rem 1rem;`"
        >
          <v-card-text
            class="text-center justify-center align-center"
            style="height: 120px"
          >
            <span>
              <v-icon size="80px">mdi-qrcode</v-icon>
            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        class="justify-center align-center"
      >
        <div
          class="mt-5"
          style="font-size: 14px;"
        >
          <b>{{ $t('dashboard.downloadAPP3') }}</b>
          <br />
          <br />
          <ol>
            <li>{{ $t('dashboard.downloadAPP4') }}</li>
            <li>{{ $t('dashboard.downloadAPP5') }}</li>
            <li>{{ $t('dashboard.downloadAPP6') }}</li>
          </ol>
        </div>
      </v-col>
      <v-col
        cols="3"
      >
        <div>
          <v-img
            width="250"
            :src="img"
            class="gamification-john-pose-2"
          ></v-img>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'

export default {
  data() {
    return {
      // eslint-disable-next-line import/no-unresolved
      img: require('@/assets/img/downloadApp/downloadApp1.svg'),
      imgs: [],
      qr: null,
      isLoadingQR: true,
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
    }
  },
  computed: {
    ...mapState({
      chatBotRead: state => state.app.chatBotRead,
    }),
  },
  created() {
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp1.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp2.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp3.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp4.svg'))
    // eslint-disable-next-line import/no-unresolved
    // this.imgs.push(require('@/assets/img/downloadApp/downloadApp5.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp6.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp7.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp8.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp9.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp10.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp11.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp12.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp13.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp14.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp15.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp16.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp17.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp18.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp19.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp20.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp21.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp22.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp23.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp24.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp25.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp26.svg'))
    // eslint-disable-next-line import/no-unresolved
    // this.imgs.push(require('@/assets/img/downloadApp/downloadApp27.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/downloadApp/downloadApp28.svg'))

    this.shuffleDeck()
  },
  methods: {
    shuffleDeck() {
      const numbers = [...this.imgs]
      let first
      let second
      let temp
      const count = numbers.length
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        first = Math.floor(Math.random() * count)
        second = Math.floor(Math.random() * count)
        temp = numbers[first]
        numbers[first] = numbers[second]
        numbers[second] = temp
      }
      this.imgs = numbers
      // eslint-disable-next-line prefer-destructuring
      this.img = this.imgs[0]
    },
  },
}
</script>
<style lang="scss" scoped>
.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  right: 0;
}
.gamification-john-pose-2 {
  bottom: 0;
  right: 15%;
}
.gamification-tree-4 {
  bottom: 0;
  right: 30%;
}

@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    right: 5%;
  }
}

@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}

@media (max-width: 400px) {
  .greeting-title {
    font-size: 1.2rem !important;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .gamification-john-pose-2 {
      right: initial;
      left: 15%;
    }
    .gamification-tree {
      right: initial;
      left: 0;
    }
    .gamification-tree-4 {
      left: 30%;
      right: initial;
    }
  }
}
</style>
